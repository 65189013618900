.robot-home-position {
    top: 45%;
    -ms-transform: translateY(-45%);
    transform: translateY(-45%);
    right: 0;
    position: absolute;
    z-index: 6;
    -webkit-animation: 22s ease-in-out 2s linear infinite;
    -webkit-animation-name: run;
    -webkit-animation-duration: 22s;
    opacity: 0;
    animation-delay: 8s;
}
@keyframes run {
    0% {
        opacity: 0;
    }
    4% {
        opacity: 0;
        right: 2%;
    }
    16% {
        opacity: 1;
        right: 2%;
    }
    20% {
        opacity: 1;
        right: 0;
    }
    35% {
        opacity: 1;
        right: 2%;
    }
    50% {
        opacity: 1;
        right: 0 ;
    }
    70% {
        opacity:0;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes run {
    0% {
        opacity: 0;
    }
    4% {
        opacity: 0;
        right: 2%;
    }
    16% {
        opacity: 1;
        right: 2%;
    }
    20% {
        opacity: 1;
        right: 0;
    }
    35% {
        opacity: 1;
        right: 2%;
    }
    50% {
        opacity: 1;
        right: 0 ;
    }
    70% {
        opacity:0;
    }
    100% {
        opacity: 0;
    }
}
@-moz-keyframes run {
    0% {
        opacity: 0;
    }
    4% {
        opacity: 0;
        right: 2%;
    }
    16% {
        opacity: 1;
        right: 2%;
    }
    20% {
        opacity: 1;
        right: 0;
    }
    35% {
        opacity: 1;
        right: 2%;
    }
    50% {
        opacity: 1;
        right: 0 ;
    }
    70% {
        opacity:0;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes run {
    0% {
        opacity: 0;
    }
    4% {
        opacity: 0;
        right: 2%;
    }
    16% {
        opacity: 1;
        right: 2%;
    }
    20% {
        opacity: 1;
        right: 0;
    }
    35% {
        opacity: 1;
        right: 2%;
    }
    50% {
        opacity: 1;
        right: 0 ;
    }
    70% {
        opacity:0;
    }
    100% {
        opacity: 0;
    }
}
@-moz-keyframes run {
    0% {
        opacity: 0;
    }
    4% {
        opacity: 0;
        right: 2%;
    }
    16% {
        opacity: 1;
        right: 2%;
    }
    20% {
        opacity: 1;
        right: 0;
    }
    35% {
        opacity: 1;
        right: 2%;
    }
    50% {
        opacity: 1;
        right: 0 ;
    }
    70% {
        opacity:0;
    }
    100% {
        opacity: 0;
    }
}
.robot-scale-home {
    -ms-transform: scale(0.35);
    transform: scale(0.35);
}

.robot-radial-gradient {
    background: radial-gradient(#011e48, #011f49, #02091c, #02071b);
}

.robot {
    height: 200px;
}

.robot * {
    box-sizing: border-box;
}


.robot .circle-bg {
    background: transparent;
    width: 250px;
    height: 250px;
    border-radius: 100%;
    /*border: transparent;*/
    position: relative;
}

.robot .circle-bg:after {
    content: "";
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: -3px;
    left: 0;
    border-radius: 100%;
}

.robot .circle-bg:hover .robot-face {
    top: 40px !important;
}

.robot .circle-bg:hover .eyes {
    top: 30px !important;
}

.robot .circle-bg:hover .mouth {
    background: #02071b;
    border: none;
    clip-path: polygon(0% 30%, 100% 30%, 100% 100%, 0% 100%);
}

.robot .circle-bg:hover .mouth:after {
    content: "";
    position: absolute;
    height: 80%;
    width: 80%;
    bottom: -30%;
    left: 50%;
    transform: translateX(-50%);
    background: #f9bbbb;
    border-radius: 100%;
}

.robot .circle-bg .robot-head {
    height: 190px;
    width: 200px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -5px;
    border: 8px solid #02071b;
    border-radius: 85px/60px;
    background: #ffffff;
    z-index: 4;
}

.robot .circle-bg .robot-head:hover {
    background: #87d0fa;
}

.robot .circle-bg .robot-head:after {
    content: "";
    position: absolute;
    top: -30px;
    height: 30px;
    width: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
}

.robot .circle-bg .robot-head:before {
    content: "";
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
    height: 25px;
    width: 25px;
    box-shadow: 0px 0px 20px 10px #14C4FF;
    background: #62a8fd;
    border: 8px solid #62a8fd;
    border-radius: 100%;
}

.robot .circle-bg .robot-head .robot-face {
    height: 120px;
    width: 160px;
    background: #d7e0e5;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    border: 8px solid #02071b;
    transition: all 200ms;
    border-radius: 50px;
}

.robot .circle-bg .robot-head .robot-face .eyes {
    height: 25px;
    width: 25px;
    background: #02071b;
    border-radius: 100%;
    position: absolute;
    transition: all 200ms;
    top: 40px;
}

.robot .circle-bg .robot-head .robot-face .eyes.left {
    left: 25px;
    animation: blink 3s 3s infinite;
}

.robot .circle-bg .robot-head .robot-face .eyes.right {
    right: 25px;
    animation: blink 3s 3s infinite;
}

@keyframes blink {
    0%, 100% {
        transform: scale(1, .05);
    }
    5%,
    95% {
        transform: scale(1, 1);
    }
}
@-moz-keyframes blink {
    0%, 100% {
        transform: scale(1, .05);
    }
    5%,
    95% {
        transform: scale(1, 1);
    }
}
@-webkit-keyframes blink {
    0%, 100% {
        transform: scale(1, .05);
    }
    5%,
    95% {
        transform: scale(1, 1);
    }
}
@-o-keyframes blink {
    0%, 100% {
        transform: scale(1, .05);
    }
    5%,
    95% {
        transform: scale(1, 1);
    }
}
@-ms-keyframes blink {
    0%, 100% {
        transform: scale(1, .05);
    }
    5%,
    95% {
        transform: scale(1, 1);
    }
}

.robot .circle-bg .robot-head .robot-face .mouth {
    height: 45px;
    width: 45px;
    border-radius: 100%;
    border: 8px solid transparent;
    border-bottom-color: #02071b;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50px;
    overflow: hidden;
}

.robot .circle-bg .robot-ear {
    position: absolute;
    height: 90px;
    width: 110px;
    border-radius: 100%;
    background: #ffffff;
    border: 8px solid #02071b;
    z-index: 3;
    top: 38px;
}

.robot .circle-bg .robot-ear.left {
    left: -10px;
}

.robot .circle-bg .robot-ear.right {
    right: -10px;
}

.robot .circle-bg .robot-body {
    height: 75px;
    width: 127px;
    border: 8px solid #02071b;
    background: #ffffff;
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100px 100px 230px 230px;
}

@media screen and (max-width: 600px) {
    .robot-scale-home {
        -ms-transform: scale(0.24);
        transform: scale(0.24);
    }
    .robot {
        height: 150px;
    }
}