/*gray text */
/*707175*/
/*818287*/
/*b8b9c1*/

html, body {
    scroll-snap-type: y proximity;
    scroll-behavior: smooth;
}

body {
    color: #818287;
    margin: -40px 0 0;
    overflow-y: overlay;
    overflow-x: hidden;
    width: 100%;
}

body::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;

}

body::-webkit-scrollbar-track {
    background-color: transparent;

}

body::-webkit-scrollbar-thumb {
    background-color: rgba(112, 113, 117, 0.32);
    border-radius: 10px;
}

a {
    text-decoration: none;
}

a:active {
    color: #c7c1c1;
}

section {
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.snapscroll {
    vertical-align: center;
    position: relative;
}

@media (min-width: 2200px) {
    .max-width-projects {
        max-width: 1950px;
    }}

@media (min-width: 960px) {
    .snapscroll {
        height: 100vh;
        margin: auto;
    }
}


@font-face {
    font-family: 'GoldenbookLight';
    src: local('Goldenbook-light'), url(./fonts/Goldenbook-light.otf) format('opentype');
}

@font-face {
    font-family: 'FreightLight';
    src: local('FreightMedium'), url(./fonts/FreightMedium.ttf) format('truetype');
}

@font-face {
    font-family: 'SofiaLight';
    src: local('SofiaLight'), url(./fonts/SofiaLight.ttf) format('truetype');
}

@font-face {
    font-family: 'SofiaBold';
    src: local('SofiaBold'), url(./fonts/SofiaBold.ttf) format('truetype');
}

h1, h2, h3, h4, h5 {
    font-family: 'FreightMedium', serif;
    color: #818287;
}

.fade-in-text {
    animation: fadeInText 1.5s;
    -webkit-animation: fadeInText 1.5s;
    -moz-animation: fadeInText 1.5s;
    -o-animation: fadeInText 1.5s;
    -ms-animation: fadeInText 1.5s;
}

@keyframes fadeInText {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeInText {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*HOME*/

.about-link {
    font-family: SofiaLight, sans-serif;
    text-decoration: none;
    color: #707175;
    float: right;
    text-transform: uppercase;
    font-size: calc(13px + 0 * (100vw - 960px) / 960);
    line-height: 1.8;
    letter-spacing: .3em;
    margin: calc(50px + 0 * (100vw - 960px) / 960);
    position:fixed;
    right:0;
    z-index:1000;
}

.left-top-link {
    font-family: SofiaLight, sans-serif;
    text-decoration: none;
    color: #707175;
    float: left;
    text-transform: uppercase;
    font-size: calc(13px + 0 * (100vw - 960px) / 960);
    line-height: 1.8;
    letter-spacing: .3em;
    margin: calc(50px + 0 * (100vw - 960px) / 960);
    position:fixed;
    left:0;
    z-index:1000;
}

.site-title {
    font-family: 'FreightMedium', serif;
    letter-spacing: 10px;
    text-transform: uppercase;
    font-size: calc(21px + 0 * (100vw - 960px) / 960);
    padding: 46vh 11vw;
    color: #818287;
}


@media screen and (min-width: 1201px) {
    .site-title {
        font-size: calc(23px + 0 * (100vw - 960px) / 960);
    }
}

@media screen and (min-width: 1920px) {
    .site-title {
        font-size: calc(30px + 0 * (100vw - 960px) / 960);
        letter-spacing: 14px;
        padding: 46vh 18vw;
    }

    .pageWidth {
        padding: 0 7vw;
    }
}

@media screen and (min-width: 2400px) {
    .site-title {
        font-size: calc(40px + 0 * (100vw - 960px) / 960);
    }
}

.my-intro {
    line-height: 2em;
    font-family: SofiaLight, sans-serif;
    letter-spacing: 3px;
    font-size: calc(18px + 0 * (100vw - 960px) / 960);
    color: #818287;
    margin: 0 auto;
    max-width: 80%;
}

@media screen and (max-width: 640px) {
    .my-intro {
        padding: 40vh 2vw;    }
}

@media screen and (min-width: 640px) {
    .my-intro {
        padding: 37vh 15vw;
    }
}

@media screen and (min-width: 1024px) {
    .my-intro {
        padding: 40vh 10vw 36vh 25vw;
        font-size: calc(23px + 0 * (100vw - 960px) / 960);
    }
}

@media screen and (min-width: 1201px) {
    .my-intro {
        font-size: calc(32px + 0 * (100vw - 960px) / 960);
    }
}

@media screen and (min-width: 1920px) {
    .my-intro {
        font-size: calc(35px + 0 * (100vw - 960px) / 960);
    }
}

@media screen and (min-width: 2400px) {
    .my-intro {
        font-size: calc(38px + 0 * (100vw - 960px) / 960);
    }
}

/*RECENT PROJECTS on Home Page*/

.align-projects {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.recent-projects-container {
    width: 100%;
    margin: auto;
    padding: 0 2.4vmin;
}

@media (min-width: 1920px) {
    .recent-projects-container {
        max-width: 1800px;
    }
}



.recent-projects {
    font-family: 'FreightMedium', serif;
    letter-spacing: 8px;
    text-transform: uppercase;
    font-size: calc(18px + 0 * (100vw - 960px) / 960);
    padding: 3em 2em 2em 5em;
    color: #818287;
}

.recent-project-container {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

@media (max-width: 961px) {
    .recent-project-container {
        display: block;
    }
}

.recent-project-title {
    text-transform: uppercase;
    font-family: 'FreightMedium', serif;
    letter-spacing: 4px;
    font-size: calc(18px + 0 * (100vw - 960px) / 960);
    color: #000;
}

.recent-skills {
    width: 8%;
    align-self: center;
    text-transform: uppercase;
    color: #707175;
    text-align: right;

}

@media (max-width: 961px) {
    .recent-skills {
        display: none
    }
}

.recent-skills p {
    color: #707175;
    letter-spacing: .05em;
    font-size: calc(14px + 0 * (100vw - 960px) / 960);
    line-height: 2.8em;
    font-family: SofiaLight, sans-serif;
}

@media (max-width: 1366px) {
    .recent-skills p {
        line-height: 1.8em;
    }
}

.recent-video {
    width: 50%;
    /*height: auto;*/
}

@media (max-width: 961px) {
    .recent-video {
        width: 100%;
        height: auto;
        text-align: center;

    }
}

.recent-project-description {
    width: 32%;
    align-self: center;
}

@media (max-width: 400px) {
    .recent-project-description {
        width: 95% !important;
    }
}
@media (max-width: 961px) {
    .recent-project-description {
        width: 90% !important;
        text-align: left;
        margin: auto;
    }
}

.project-description-text {
    color: #a5a5a6;
    letter-spacing: .03em;
    font-size: calc(17px + 0 * (100vw - 960px) / 960);
    line-height: 1.8;
    font-family: SofiaLight, sans-serif;
}

@media (max-width: 1260px) {
    .project-description-text {
        line-height: 1.4;
    }
}

.arrow {
    border: none;
    background-color: transparent;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    z-index: 2;
}

@media (min-width: 1920px) {
    .arrow {
        bottom: 3em;
    }
}
@media (min-width: 2500px) {
    .arrow {
        bottom: 4.5em;
    }
}

.hidden-btn {
    background-color: transparent;
    border: none;
}

.back-to-top {
    padding-top: 7em;
    display: flex;
    justify-content: center;
}
.home-btn-text {
    font-family: SofiaLight, sans-serif;
    text-decoration: none;
    color: #707175;
    text-transform: uppercase;
    font-size: calc(14px + 0 * (100vw - 960px) / 960);
    line-height: 1.8;
    letter-spacing: .3em;
}

hr {
    border:0;
    border-top: 1px solid #E8E9F1;
    margin-block-start: 0.01em;
    margin-block-end: 0.01em;
}

/*FadeInSection*/
.fade-in-section {
    opacity: 0;
    transform: translateY(30vh);
    visibility: hidden;
    transition: opacity 1s ease-out, transform 1.4s ease-out;
    will-change: opacity, visibility;
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}