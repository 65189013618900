.past-projects-left {
    width: 28%;
    display: flex;
    justify-content: right;
    align-items: center;
    text-transform: uppercase;
    color: #818287;
    font-family: GoldenbookLight, serif;
    font-size: calc(18px + 0 * (100vw - 960px) / 960);
    line-height: 1.8;
    letter-spacing: .3em;
}

@media screen and (max-width: 960px) {
    .past-projects-left {
        padding-bottom: 2em;
        width: 100%;
        justify-content: left;
        font-size: calc(18px + 0 * (100vw - 960px) / 960);
        padding-left: 2rem;
    }
}

.list-past-projects {
    width: 65%;
    text-align: left;
    font-size: 1.5625rem;
    color: #b8b9c1;
}

/* HOVER REVEAL*/

.index-container {
    position: relative;
    text-align: right;
}

.index-items {
    position: relative;
    text-transform: uppercase;
}

@media screen and (max-width: 650px) {
    .index-sections {
        max-width: 100%;
        display: block;
        padding: 0;
    }
}

.index {
    position: relative;
    display: grid;
    justify-items: start;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    counter-reset: indexcounter;
}

/*@media screen and (min-width: 53em) {*/
/*    .index {*/
/*        padding-top: 15vh;*/
/*    }*/
/*}*/

.index__item {
    transition: all 1s ease;
    padding-bottom: 2.2vw;
    justify-content: start;
    position: relative;
    display: grid;
    width: 100%;
    grid-template-areas: 'counter text';
    grid-template-columns: 3rem auto;
    grid-template-rows: auto;
}


.index__item::before {
    text-align: right;
    grid-area: counter;
    align-self: start;
    justify-self: end;
    color: #a4a4a4;
    counter-increment: indexcounter;
    content: counters(indexcounter, ".", decimal-leading-zero);
    opacity: 0;
    font-family: FreighMedium, serif;
    font-weight: 100;
    line-height: 1;
    padding-right: 0.5rem;
    display: grid;
    place-items: center;
    font-size: calc(21px + 0 * (100vw - 960px) / 960);
    transform: translateX(1rem);
    transition: transform 0.3s, opacity 0.3s;
}

.index__item:hover::before {
    opacity: 1;
    transform: translateX(0);
    padding: 0 0.4em 0.2em 0.4em;
}

.index__item-text {
    grid-area: text;
    position: relative;
    cursor: pointer;
    display: block;
    overflow: visible;
    line-height: 1;
    height: 100%;
    padding: 1vh 0;
    font-size: calc(21px + 0 * (100vw - 960px) / 960);
}


@media screen and (min-width: 1059px) {
    .index__item-text {
        font-size: calc(21px + 0 * (100vw - 960px) / 960);
    }

}

.index__item-textinner {
    font-size: calc(20px + 6 * ((100vw - 320px) / 680));
    line-height: 1.5;
    color: #818287;
    letter-spacing: .1em;
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    font-family: FreighMedium, serif;
    font-weight: 100;
    font-style: normal;
    text-transform: uppercase;
    white-space: nowrap;
    padding-bottom: .7em;
    transition: all 1s ease;

}

.past-skills-text {
    letter-spacing: .03em;
    /*margin-top: 2em;*/
    font-size: calc(16px + 0 * (100vw - 960px) / 960);
    line-height: 1.8;
    font-family: SofiaLight, sans-serif;
    position: absolute;
    color: #b8b9c1;
    left: 0;
    transition: all 1s ease;

}

@media screen and (min-width: 1024px) {
    .index__item-textinner {
        font-size: calc(29px + 0 * (100vw - 960px) / 960);
    }
}

@media screen and (min-width: 1920px) {

    .index__item-textinner {
        font-size: calc(35px + 0 * (100vw - 960px) / 960);
    }
}

@media screen and (min-width: 2400px) {
    .index__item-textinner {
        font-size: calc(38px + 0 * (100vw - 960px) / 960);
    }
}


.js .index__item-textinner {
    transform: translateY(100%);
}

.index__item-sub {
    grid-area: sub;
    color: #343434;
    display: none;
    font-size: 1rem;
    font-family: FreighMedium, serif;
    white-space: nowrap;
    position: relative;
    width: 100%;
    line-height: 1;
    padding: 0 1rem 0 3rem;
    opacity: 0;
    margin-left: auto;
    transform: translateX(-1rem);
    transition: transform 0.3s, opacity 0.3s;
}

.index__item:hover .past-skills-text {
    color: #343434;
}

.index__item:hover .index__item-textinner {
    color: #343434;
    z-index: 1;

}

.index__item:hover .reveal video {
    z-index: -1;
}


.index__item:hover .index__item-sub {
    opacity: 1;
    transform: translateX(0);
    color: #343434;
}


@media screen and (min-width: 53em) {
    .index__item {
        grid-template-areas:
		'. counter text . .'
		'. . sub . .';
        grid-template-columns: 1fr 7rem auto 7rem 1fr;
    }

    .index__item-sub {
        display: block;
    }
}

@media (min-width: 768px) {
    .reveal video {
        z-index: -5;
        display: block;
        width: 500px;
        height: auto;
        top: 0;
        position: absolute;
        opacity: 0;
        -webkit-transition: opacity .8s ease-in-out;
        -moz-transition: opacity .8s ease-in-out;
        -ms-transition: opacity .8s ease-in-out;
        -o-transition: opacity .8s ease-in-out;
        transition: opacity .8s ease-in-out;
        overflow: visible;
    }
}

.index__item:hover .reveal video {
    display: block;
    text-align: center;
    width: 500px;
    height: auto;
    top: 0;
    position: absolute;
    zoom: 1;
    filter: alpha(opacity=50);
    opacity: 1;
    overflow: visible;
}

@media (min-width: 768px) {
    .drupaltop video {
        margin-top: 2em;
        margin-left: 0;
    }
    .springtop video {
        margin-top: 2em;
        margin-left: 0;
    }
    .asutop video {
        margin-top: 2em;
        margin-left: 0;
    }
    .crystaltop video {
        margin-top: 2em;
        margin-left: 0;
    }
}

.index__item:hover .drupaltop video {
    margin-top: 2em;
    margin-left: 0;
}
.index__item:hover .springtop video {
    margin-top: 2em;
    margin-left: 0;
}
.index__item:hover .asutop video {
    margin-top: 2em;
    margin-left: 0;
}
.index__item:hover .crystaltop video {
    margin-top: 2em;
    margin-left: 0;
}

@media (min-width: 1200px) {
     .drupaltop video  {
         margin-top:  -15%;
         margin-left: -245%;
    }
    .springtop video  {
        margin-top: -40%;
        margin-left: -276%;
    }
    .asutop video  {
        margin-top: -60%;
        margin-left: -268%;
    }
    .crystaltop video  {
        margin-top: -95%;
        margin-left: -268%;
    }
}

@media (min-width: 1200px) {
    .index__item:hover .drupaltop video  {
        margin-top: -15%;
        margin-left: -245%;
    }
    .index__item:hover .springtop video  {
        margin-top: -40%;
        margin-left: -276%;
    }
    .index__item:hover .asutop video  {
        margin-top: -60%;
        margin-left: -268%;
    }
    .index__item:hover .crystaltop video  {
        margin-top: -95%;
        margin-left: -268%;
    }
}

@media (max-width: 767px) {
    .reveal video {
        display: none;
    }
    .index__item:hover .reveal video {
        display: none;
    }
    .index__item:hover .drupaltop video  {
        margin-left: -245%;
        margin-top: -15%;
    }
    .index__item:hover .springtop video  {
        margin-top: -15%;
        margin-left: -274%;
    }
    .index__item:hover .asutop video  {
        margin-top: -50%;
        margin-left: -268%;
    }
    .index__item:hover .crystaltop video  {
        margin-top: -95%;
        margin-left: -268%;
    }
}
