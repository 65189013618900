/*Force fluid video resizing for all device sizes*/

@media screen and (min-width: 900px) {
    .videoMargins {
        padding: 2% 10% 0;
    }

    .videoBottomMarg {
        padding-bottom: 1em;
    }
}

.videoWrapper {
    position: relative;
    padding-bottom: 62%; /* 16:9 */
    height: 0;
}

@media screen and (max-width: 899px) {
    .videoBottomMarg {
        padding-bottom: 1.2em;
    }
}

.videoWrapper iframe {
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
}
