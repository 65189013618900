.story-mage-background-image-container {
    position: relative;
}

.story-mage-image {
    width: 100%;
    height: 100%;
    z-index: 3;
}

.story-mage-background-image {
    width: 100%;
    position: absolute;
}

.story-image-fade-out {
    animation: fade-out-story-image 22s ease-in-out infinite;
    -webkit-animation: fade-out-story-image 22s ease-in-out infinite;
    -moz-animation: fade-out-story-image 22s ease-in-out infinite;
    -o-animation: fade-out-story-image 22s ease-in-out infinite;
    -ms-animation: fade-out-story-image 22s ease-in-out infinite;
}

@keyframes fade-out-story-image {
    0% {
        opacity: 0
    }
    3% {
        opacity: 1
    }
    8% {
        opacity: 1
    }

    20% {
        opacity: 0
    }
    90% {
        opacity: 0
    }
    95% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@-moz-keyframes fade-out-story-image {
    0% {
        opacity: 0
    }
    3% {
        opacity: 1
    }
    8% {
        opacity: 1
    }

    20% {
        opacity: 0
    }
    90% {
        opacity: 0
    }
    95% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}
@-webkit-keyframes fade-out-story-image {
    0% {
        opacity: 0
    }
    3% {
        opacity: 1
    }
    8% {
        opacity: 1
    }

    20% {
        opacity: 0
    }
    90% {
        opacity: 0
    }
    95% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@-o-keyframes fade-out-story-image {
    0% {
        opacity: 0
    }
    3% {
        opacity: 1
    }
    8% {
        opacity: 1
    }
    20% {
        opacity: 0
    }
    90% {
        opacity: 0
    }
    95% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@-ms-keyframes fade-out-story-image {
    0% {
        opacity: 0
    }
    3% {
        opacity: 1
    }
    8% {
        opacity: 1
    }
    20% {
        opacity: 0
    }
    90% {
        opacity: 0
    }
    95% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

/* Fairytale fade-in-out effect */

.fairytale-text {
    position: absolute;
    line-height: 25px;
    color: white;
    z-index: 5;
    top: 45%;
    -ms-transform: translateY(-45%);
    transform: translateY(-45%);
    left: 10%;
    right: 10%;
    text-align: center;
    font-family: SofiaLight, sans-serif;
    font-style: italic;
    letter-spacing: 3px;
    font-size: calc(15px + 0 * (100vw - 960px) / 960);

    animation: fairytale-text 22s ease-in-out 2s infinite;
    -webkit-animation: fairytale-text 22s ease-in-out 2s infinite;
    -moz-animation: fairytale-text 22s ease-in-out 2s infinite;
    -o-animation: fairytale-text 22s ease-in-out 2s infinite;
    -ms-animation: fairytale-text 22s ease-in-out 2s infinite;

    opacity: 0;
    animation-delay: 1s;
}

.delayed-text {
    animation-delay: 2s;
    margin-top: 3em;

}

@keyframes fairytale-text {
    0% {
        opacity: 0
    }
    3% {
        opacity: 1
    }
    10% {
        opacity: 1
    }
    20% {
        opacity: 0
    }
    /* 20% of 5 seconds = 1 second */
    90% {
        opacity: 0
    }
    97% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

@-moz-keyframes fairytale-text {
    0% {
        opacity: 0
    }
    3% {
        opacity: 1
    }
    10% {
        opacity: 1
    }
    20% {
        opacity: 0
    }
    90% {
        opacity: 0
    }
    97% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

@-webkit-keyframes fairytale-text {
    0% {
        opacity: 0
    }
    3% {
        opacity: 1
    }
    10% {
        opacity: 1
    }
    20% {
        opacity: 0
    }
    90% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

@-o-keyframes fairytale-text {
    0% {
        opacity: 0
    }
    3% {
        opacity: 1
    }
    10% {
        opacity: 1
    }
    20% {
        opacity: 0
    }
    90% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

@-ms-keyframes fairytale-text {
    0% {
        opacity: 0
    }
    3% {
        opacity: 1
    }
    10% {
        opacity: 1
    }
    20% {
        opacity: 0
    }
    90% {
        opacity: 0
    }
    97% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

.what-if-text {
    position: absolute;
    top: 45%;
    -ms-transform: translateY(-45%);
    transform: translateY(-45%);
    line-height: 1.5em;
    color: white;
    z-index: 5;
    left: 10%;
    right: 10%;
    text-align: center;
    font-family: SofiaLight, sans-serif;
    font-style: italic;
    letter-spacing: 3px;
    font-size: calc(15px + 0 * (100vw - 960px) / 960);
    animation: what-if-text 22s ease-in-out 2s infinite;
    -webkit-animation: what-if-text 22s ease-in-out 2s infinite;
    -moz-animation: what-if-text 22s ease-in-out 2s infinite;
    -o-animation: what-if-text 22s ease-in-out 2s infinite;
    -ms-animation: what-if-text 22s ease-in-out 2s infinite;
    opacity: 0;
    animation-delay: 6s;
}
@keyframes what-if-text {
    0% {
        opacity: 0
    }
    4% {
        opacity: 1
    }
    10% {
        opacity: 1
    }
    16% {
        opacity: 0
    }
    20% {
        opacity: 0
    }
    /* 20% of 5 seconds = 1 second */
    90% {
        opacity: 0
    }
    97% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

@-moz-keyframes what-if-text {
    0% {
        opacity: 0
    }
    4% {
        opacity: 1
    }
    10% {
        opacity: 1
    }
    16% {
        opacity: 0
    }
    20% {
        opacity: 0
    }
    /* 20% of 5 seconds = 1 second */
    90% {
        opacity: 0
    }
    97% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

@-webkit-keyframes what-if-text {
    0% {
        opacity: 0
    }
    4% {
        opacity: 1
    }
    10% {
        opacity: 1
    }
    16% {
        opacity: 0
    }
    20% {
        opacity: 0
    }
    /* 20% of 5 seconds = 1 second */
    90% {
        opacity: 0
    }
    97% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

@-o-keyframes what-if-text {
    0% {
        opacity: 0
    }
    4% {
        opacity: 1
    }
    10% {
        opacity: 1
    }
    16% {
        opacity: 0
    }
    20% {
        opacity: 0
    }
    /* 20% of 5 seconds = 1 second */
    90% {
        opacity: 0
    }
    97% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

@-ms-keyframes what-if-text {
    0% {
        opacity: 0
    }
    4% {
        opacity: 1
    }
    10% {
        opacity: 1
    }
    16% {
        opacity: 0
    }
    20% {
        opacity: 0
    }
    /* 20% of 5 seconds = 1 second */
    90% {
        opacity: 0
    }
    97% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

.bot-text {
    position: absolute;
    padding: 1em 2em;
    top: 45%;
    -ms-transform: translateY(-45%);
    transform: translateY(-45%);
    line-height: 2em;
    color: white;
    left: 2%;
    right: 35%;
    text-align: left;
    font-family: SofiaLight, sans-serif;
    letter-spacing: 2px;
    font-size: calc(15px + 0 * (100vw - 960px) / 960);
    z-index: 6;

    -webkit-animation: 22s ease-in-out 2s linear infinite;
    animation: 22s ease-in-out 2s linear infinite;
    -moz-animation: 22s ease-in-out 2s linear infinite;
    -o-animation: 22s ease-in-out 2s linear infinite;
    -ms-animation: 22s ease-in-out 2s linear infinite;
    animation-name: bot-text;
    -webkit-animation-duration: 22s;
    opacity: 0;
    animation-delay: 7s;
}
@media (max-width: 600px) {
    .bot-text {
        font-size: calc(14px + 0 * (100vw - 960px) / 960);
        line-height: 1.5em;}
}
@keyframes bot-text {
    0% {
        opacity: 0;
    }
    8% {
        opacity: 0;
    }
    16% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes bot-text {
    0% {
        opacity: 0;
    }
    8% {
        opacity: 0;
    }
    16% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes bot-text  {
    0% {
        opacity: 0;
    }
    8% {
        opacity: 0;
    }
    16% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes bot-text  {
    0% {
        opacity: 0;
    }
    8% {
        opacity: 0;
    }
    16% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-ms-keyframes bot-text {
    0% {
        opacity: 0;
    }
    8% {
        opacity: 0;
    }
    16% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
