/*Past Project Page*/

.pages-project-header {
    height: 75px;
    display: flex;
    justify-content: space-between;
    margin-top: calc(19px + 0 * (100vw - 960px) / 960)
}

.pages-project-section {
    margin: 0 auto;
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 5em 0;
}

.pages-project-wide {
    display: block;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    padding: 7vw 0;
}

@media (max-width: 961px) {
    .pages-project-section {
        display: block;
        width: 90%;
    }
    .pages-project-wide {
        display: block;
        width: 90%;
    }
    .hidden {
        display: none;
    }


}

@media (min-width: 962px) and (max-width: 1148px) {
    .pages-project-section {
        width: 90%;
    }
}

.pages-project-title {
    text-transform: uppercase;
    font-family: 'FreightMedium', serif;
    letter-spacing: 4px;
    font-size: calc(18px + 0 * (100vw - 960px) / 960);
    color: #555;
    font-weight: bold;
}

.pages-image {
    width: 60%;
    height: auto;
    padding-right: 5%;
}

.pages-wide-image {
    display: block;
    width: 100%;
    height: auto;
}

.drupal-image {
    width: 75%;
    height: auto;
    padding-right: 5%;
}

@media (max-width: 961px) {
    .pages-image {
        width: 100%;
    }
    .drupal-image {
        width: 100%;
    }
    .pages-project-title {
        padding-top: 1em;
    }
}

.pages-project-description {
    width: 37%;
    align-self: stretch;
}

.drupal-description {
    width: 20%;
    align-self: stretch;
}

.wide-pages-project-description {
    width: 83%;
    text-align: left;
    margin: 0 auto;
}


@media (max-width: 961px) {
    .pages-project-description {
        width: 90% !important;
        text-align: justify;
        padding: 0 2em;
    }
    .drupal-description {
        width: 90% !important;
        text-align: justify;
        padding: 0 2em;
    }
}

.launch {
    font-family: SofiaBold, sans-serif;
    text-decoration: none;
    color: #555;
    text-transform: uppercase;
    font-size: calc(14px + 0 * (100vw - 960px) / 960);
    line-height: 1.8;
    letter-spacing: .3em;
}

.drupal-launch {
    font-family: Sofia, sans-serif;
    text-decoration: none;
    color: #555;
    text-transform: uppercase;
    font-size: calc(12px + 0 * (100vw - 960px) / 960);
    line-height: 1.8;
    letter-spacing: .3em;
}

.pages-full-image {
    width: 100%;
    height: auto;
}

/*Videos*/
.pages-video {
    width: 100%;
    height: auto;
    padding-bottom: 5em;
}

/*Force fluid video resizing for all device sizes*/
.videoWrapper {
    position: relative;
    padding-bottom: 62%; /* 16:9 */
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
}

.btn-section {
    width: 100%;
    background-color: transparent;
    border: none;
    margin: 5em 0 0;
}

.pages-uparrow {
    border: none;
    background-color: transparent;
    text-align: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    z-index: 2;
}

/*Testimonials */
.testimonials {
    margin: 0 auto;
    width: 70%;
    padding: 8vw 0;
    color: #555;
    letter-spacing: .03em;
    font-size: calc(17px + 0 * (100vw - 960px) / 960);
    line-height: 1.8;
    font-family: SofiaLight, sans-serif;
    display: block;
    text-align: center;
}

.testimonial-name {
    float:right;
    padding: 1em;
    font-style: italic;
}

img.left-side {
    width: 250px;
    height: auto;
}

.testimonial-text {
text-align: justify;
}

@media (min-width: 1024px) {
    .testimonials {
        display: flex;
        align-items: center;
    }

    img.left-side {
        float: left;
        padding-right: 5%;
    }

    .testimonial-text {
        text-align: left;
    }
}


.content img.left-side {
    max-width: 100%;
}

/*Fade-in Section*/
.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1000ms ease-out, transform 500ms ease-out,
    visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.buttons-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding-top: 4em;
}

/*.scale {*/
/*    transition: all 0.7s ease-in-out;*/
/*}*/
/*.scale:hover {*/
/*    transform: scale(1.1);*/
/*    transition: 0.7s;*/
/*}*/

